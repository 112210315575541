



















import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
import BaseButton from '@/components/base/BaseButton.vue'

@Component({
    name: 'ModalMoreApplications',
    components: {
        Modal,
        BaseButton
    },
})
export default class extends Vue {
    @Prop({ required: true })
    show!: boolean

    @Prop()
    onClose!: () => void

  cancel_text = ''
  accept_text = ''
  title_text = ''
  content_text = ''


    public handleClick(): void {
       this.onClose()
        // window.open("https://api.whatsapp.com/send/?phone=74952121155&text&type=phone_number&app_absent=0","_blank")

       this.$router.push({ name: 'Premium' })
    }

  private async created() {
    const content = await this.$api.common.getContentBySlug('/b2b_adv_message')
    if (content.data) {
      const data = content.data
      if (data.bsteps_content) {
        const content = data.bsteps_content
        this.cancel_text = content.cancel_text
        this.accept_text = content.accept_text
        this.title_text = content.title_text
        this.content_text = content.content_text

      }
    }
  }
}
